import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../css/contactForm.css';

const ContactForm = ({ setShowContactForm }) => {
  const [contactEmail, setContactEmail] = useState('');
  const [contactSubject, setContactSubject] = useState('');
  const [contactText, setContactText] = useState('');
  const [contactLoading, setContactLoading] = useState(false);
  const [contactSuccess, setContactSuccess] = useState(false);

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    setContactLoading(true);

    const templateParams = {
      from_email: contactEmail,
      subject: contactSubject,
      message: contactText,
    };

    emailjs.send('service_25igw0g', 'template_j20jnm9', templateParams, 'MWOLOanS95GyXZ7jp')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setContactLoading(false);
        setContactSuccess(true);
        setShowContactForm(false);
        setContactEmail('');
        setContactSubject('');
        setContactText('');
        setTimeout(() => setContactSuccess(false), 2000); // Hide success message after 2 seconds
      }, (error) => {
        console.error('FAILED...', error);
        setContactLoading(false);
      });
  };

  return (
    <div className="contact-form-overlay">
      <div className="contact-form">
        <h2>Contact Us</h2>
        <form onSubmit={handleContactSubmit}>
          <input
            type="email"
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
            placeholder="Your Email"
            required
          />
          <input
            type="text"
            value={contactSubject}
            onChange={(e) => setContactSubject(e.target.value)}
            placeholder="Subject"
            required
          />
          <textarea
            value={contactText}
            onChange={(e) => setContactText(e.target.value)}
            placeholder="Your Message"
            required
          />
          <button type="submit" disabled={contactLoading}>Send</button>
          <button type="button" onClick={() => setShowContactForm(false)}>Cancel</button>
        </form>
        {contactLoading && <div className="loading-bar"></div>}
        {contactSuccess && <div className="success-message">Message sent successfully!</div>}
      </div>
    </div>
  );
};

export default ContactForm;
