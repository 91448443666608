import React, {useState} from 'react';
import Modal from 'react-modal';
import '../css/cancelSubscription.css';
import LogRocket from "logrocket";

const env = process.env.NODE_ENV;

let backend_address;

if (env === 'production') {
    backend_address = 'https://api.newsinfobot.com';
    LogRocket.init('ielsqo/chat-news');
} else {
    backend_address = 'http://localhost:5555';
}


const CancelSubscriptionModal = ({isOpen, onRequestClose}) => {
    const [apiKey, setApiKey] = useState('');

    const handleCancelSubscription = () => {
        fetch(`${backend_address}/api/cancel_subscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                api_key: apiKey
            })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    alert('Subscription cancelled successfully.');
                    onRequestClose();
                } else {
                    alert('Failed to cancel subscription. Please try again.');
                }
            });
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="cancel-subscription-modal"
            overlayClassName="cancel-subscription-overlay"
        >
            <div className="modal-content">
                <h2 style={{color: 'black'}}>Cancel Subscription</h2>
                <p style={{color: 'black'}}>
                    Please enter your API key (It has been emailed to you when you subscribed):
                </p>
                <input
                    type="text"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    placeholder="API Key"
                    required
                />
                <div className="button-container">

                    <button onClick={handleCancelSubscription}>Cancel Subscription</button>
                    <button onClick={onRequestClose}>Close</button>
                </div>
            </div>
        </Modal>
    );
};

export default CancelSubscriptionModal;
