import React, { useState } from 'react';
import '../css/successfulPaymentAlert.css';

const SuccessfulPaymentAlert = ({ message, apiKey, onClose }) => {
    const [copyFeedback, setCopyFeedback] = useState('');

    const handleCopy = () => {
        navigator.clipboard.writeText(apiKey).then(() => {
            setCopyFeedback('API Key copied!');
            setTimeout(() => setCopyFeedback(''), 2000); // Clear feedback after 2 seconds
        });
    };

    return (
        <div className="successful-payment-alert-overlay">
            <div className="successful-payment-alert">
                <div className="successful-payment-alert-content">
                    <p>{message}</p>
                    <p><strong>API Key:</strong> {apiKey}</p>
                    <div className="alert-buttons">
                        <button onClick={handleCopy}>Copy API</button>
                        <button onClick={onClose}>Close</button>
                    </div>
                    {copyFeedback && <p className="copy-feedback">{copyFeedback}</p>}
                </div>
            </div>
        </div>
    );
};

export default SuccessfulPaymentAlert;
