import React from 'react';

const TermsOfUse = () => (
  <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
    <h1>Terms of Use</h1>
    <p><strong>Effective Date:</strong> 1st of May, 2024 </p>
    <p>Welcome to News Bot ("Service"), By accessing or using our Service, you agree to be bound by these Terms of Use ("Terms"). If you do not agree with these Terms, you must not use the Service.</p>
    <h2>1. Acceptance of Terms</h2>
    <p>By accessing and using the Service, you accept and agree to be bound by these Terms and all applicable laws and regulations. If you do not agree with any part of these Terms, you are prohibited from using the Service.</p>
    <h2>2. Service Description</h2>
    <p>News Bot is a one-page application that provides real-time information by browsing the web. The Service includes monthly subscription payments via PayPal, which can be canceled at any time. Subscribers receive an API key for backend calls as part of their subscription.</p>
    <h2>3. Subscription and Payment</h2>
    <ul>
      <li><strong>Subscription:</strong> the Service requires a monthly subscription, which grants access to an API key for backend calls.</li>
      <li><strong>Payment:</strong> Payments are processed via PayPal. By subscribing, you authorize me to charge your PayPal account the monthly subscription fee.</li>
      <li><strong>Cancellation:</strong> You can cancel your subscription at any time. Upon cancellation, you will retain access to the Service until the end of the current billing cycle.</li>
    </ul>
    <h2>4. Use of Service</h2>
    <p><strong>Eligibility:</strong> You must be at least 18 years old to use the Service.</p>
    <p><strong>Prohibited Conduct:</strong> You agree not to use the Service for any unlawful purpose or in a way that could harm the Service or its users. This includes, but is not limited to, attempting to gain unauthorized access to the systems, spreading malware, or engaging in any form of data scraping.</p>
    <h2>5. Intellectual Property</h2>
    <p>All content, features, and functionality of the Service, including but not limited to text, graphics, logos, and software, are owned by me or the licensors and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
    <h2>6. Disclaimer of Warranties</h2>
    <p>The Service is provided on an "as is" and "as available" basis. I make no warranties, express or implied, regarding the reliability, accuracy, or completeness of the information provided by the Service. I disclaim all warranties, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
    <h2>7. Limitation of Liability</h2>
    <p>To the fullest extent permitted by law, I shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of the Service.</p>
    <h2>8. Indemnification</h2>
    <p>You agree to indemnify, defend, and hold harmless [Your Name] from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Service or your violation of these Terms.</p>
    <h2>9. Governing Law</h2>
    <p>These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.</p>
    <h2>10. Changes to Terms</h2>
    <p>I reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on the Service. Your continued use of the Service following the posting of changes constitutes your acceptance of such changes.</p>
    <h2>11. Contact Me</h2>
    <p>If you have any questions about these Terms, please contact me at newsinfobots@gmail.com.</p>
  </div>
);

export default TermsOfUse;
