import React, {useState} from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {a11yDark} from 'react-syntax-highlighter/dist/esm/styles/hljs';  // Changed to a dark theme
import axios from 'axios';
import '../css/codeExamples.css';
import LogRocket from "logrocket";  // Import the new CSS file


const env = process.env.NODE_ENV;

let backend_address;

if (env === 'production') {
    backend_address = 'https://api.newsinfobot.com';
    LogRocket.init('ielsqo/chat-news');
} else {
    backend_address = 'http://localhost:5555';
}


const CodeExamples = ({setCodeModalIsOpen}) => {
    const [language, setLanguage] = useState('python');
    const [apiKey, setApiKey] = useState('');
    const [message, setMessage] = useState('');
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);


    const codeSnippets = {
        python: `
import requests

url = '${backend_address}/api/paid/message'
api_key = '${apiKey}'
message = '${message}'

response = requests.post(url, json={'api_key': api_key, 'message': message})
print(response.json())
        `,
        nodejs: `
const axios = require('axios');

const url = '${backend_address}/api/paid/message';
const api_key = '${apiKey}';
const message = '${message}';

axios.post(url, {
    api_key: api_key,
    message: message
})
.then(response => {
    console.log(response.data);
})
.catch(error => {
    console.error('Error:', error);
});
        `
        // Add more languages as needed
    };

    const sendTestRequest = async () => {
        setLoading(true);
        try {
            const res = await axios.post(`${backend_address}/api/paid/message`, {
                api_key: apiKey,
                message: message
            });
            setResponse(res.data);
        } catch (error) {
            setResponse({error: 'Error sending message'});
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="code-examples">
            <div className="tabs">
                <button className={language === 'python' ? 'active' : ''} onClick={() => setLanguage('python')}>Python
                </button>
                <button className={language === 'nodejs' ? 'active' : ''}
                        onClick={() => setLanguage('nodejs')}>Node.js
                </button>
                {/* Add more tabs for other languages */}
            </div>
            <div className="code-container">
                <SyntaxHighlighter language={language} style={a11yDark}>
                    {codeSnippets[language]}
                </SyntaxHighlighter>
            </div>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Enter your API key"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    className="input-field"
                />
                <input
                    type="text"
                    placeholder="Enter your message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="input-field"
                />
                <button onClick={sendTestRequest} className={`send-button ${loading ? 'loading' : ''}`}
                        disabled={loading}>
                    {loading ? 'Loading...' : 'Send Test Request'}
                </button>
            </div>
            {response && (
                <div className="response">
                    <h3>Response:</h3>
                    <pre>{JSON.stringify(response, null, 2)}</pre>
                </div>
            )}
        </div>
    );
};

export default CodeExamples;
