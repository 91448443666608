import React, { useState } from 'react';
import '../css/chatMenu.css';
import Modal from 'react-modal';
import PaypalButton from './PaypalButton';
import CodeExamples from './CodeExamples';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import TermsOfUse from './TermsOfUse'; // Import the new Terms of Use component

Modal.setAppElement('#root');

const ChatMenu = ({ setShowContactForm }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [codeModalIsOpen, setCodeModalIsOpen] = useState(false);
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);

  const openTermsOfUse = () => {
    const termsWindow = window.open('', '_blank');
    termsWindow.document.write(`
      <html>
        <head><title>Terms of Use</title></head>
        <body>${document.getElementById('terms-of-use').innerHTML}</body>
      </html>
    `);
  };

  return (
    <div className="chat-menu">
      <div>
        <div className="explanation">
          <h2>Why Use This Bot?</h2>
          <p>
            This AI bot specializes in providing real-time information by browsing the web.
            Use it to get the latest news on companies, quarterly results, or summaries of news on any subject.
            Unlike other bots, this one supports real-time data fetching and offers an API for enhanced integration.
          </p>
        </div>
        <div className="menu">
          <button onClick={() => setModalIsOpen(true)} className="bullet-button">Get API Key</button>
          <button onClick={() => setShowContactForm(true)} className="bullet-button" disabled={modalIsOpen}>Contact Us</button>
          <button onClick={() => setCodeModalIsOpen(true)} className="bullet-button" disabled={modalIsOpen}>Code Examples</button>
          <button onClick={openTermsOfUse} className="bullet-button">Terms of Use</button> {/* New button */}
        </div>
      </div>
      <button onClick={() => setCancelModalIsOpen(true)} className="bullet-button cancel-button" disabled={modalIsOpen}>Cancel Subscription</button>
      <PaypalButton modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-content">
          <h2>Get API Key</h2>
          <button onClick={() => setModalIsOpen(false)}>Close</button>
        </div>
      </Modal>
      <Modal
        isOpen={codeModalIsOpen}
        onRequestClose={() => setCodeModalIsOpen(false)}
        className="Modal"
        overlayClassName="Overlay"
      >
        <CodeExamples setCodeModalIsOpen={setCodeModalIsOpen} />
      </Modal>
      <CancelSubscriptionModal
        isOpen={cancelModalIsOpen}
        onRequestClose={() => setCancelModalIsOpen(false)}
      />
      <div id="terms-of-use" style={{ display: 'none' }}>
        <TermsOfUse />
      </div>
    </div>
  );
};

export default ChatMenu;
