import React from 'react';
import '../css/chatContainer.css';

const ChatContainer = ({ messages, loading, sendMessage, setMessage, message }) => (
  <div className="chat-container">
    <div className="messages">
      {messages.map((msg, index) => (
        <div key={index} className={`message ${msg.user === 'You' ? 'user-message' : 'bot-message'}`}>
          <strong>{msg.user}:</strong> {msg.text.split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
      ))}
      {loading && <div className="loading">Loading...</div>}
    </div>
    <form onSubmit={sendMessage} className="message-form">
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Enter your message"
        disabled={loading}
      />
      <button type="submit" disabled={loading}>Send</button>
    </form>
  </div>
);

export default ChatContainer;
