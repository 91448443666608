import React, { useState } from 'react';
import './css/global.css';
import LogRocket from 'logrocket';
import axios from 'axios';
import ChatMenu from './components/ChatMenu';
import ChatContainer from './components/ChatContainer';
import ContactForm from './components/ContactForm';
import Alert from './components/Alert';

const env = process.env.NODE_ENV;

let backend_address;

if (env === 'production') {
    backend_address = 'https://api.newsinfobot.com';
    LogRocket.init('ielsqo/chat-news');
} else {
    backend_address = 'http://localhost:5555';
}

function App() {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showContactForm, setShowContactForm] = useState(false);
    const [contactEmail, setContactEmail] = useState('');
    const [contactSubject, setContactSubject] = useState('');
    const [contactText, setContactText] = useState('');
    const [contactLoading, setContactLoading] = useState(false);
    const [contactSuccess, setContactSuccess] = useState(false);

    const sendMessage = async (e) => {
        e.preventDefault();
        if (!message.trim()) return;
        setMessages((prevMessages) => [
            ...prevMessages,
            { user: 'You', text: message }
        ]);
        setMessage('');
        setLoading(true);
        try {
            const response = await axios.post(`${backend_address}/api/message`, { message });
            const responseMessage = response.data.response;
            setMessages((prevMessages) => [
                ...prevMessages,
                { user: 'Bot', text: responseMessage }
            ]);
        } catch (error) {
            console.error("Error sending message:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleContactSubmit = (e) => {
        e.preventDefault();
        setContactLoading(true);
        setTimeout(() => {
            setContactLoading(false);
            setContactSuccess(true);
            setShowContactForm(false);
            setContactEmail('');
            setContactSubject('');
            setContactText('');
            setTimeout(() => setContactSuccess(false), 2000);
        }, 1000);
    };

    return (
        <div className="App">
            <ChatMenu setShowContactForm={setShowContactForm} />
            <ChatContainer
                messages={messages}
                loading={loading}
                sendMessage={sendMessage}
                setMessage={setMessage}
                message={message}
            />
            {showContactForm && (
                <ContactForm
                    handleContactSubmit={handleContactSubmit}
                    setShowContactForm={setShowContactForm}
                    contactEmail={contactEmail}
                    setContactEmail={setContactEmail}
                    contactSubject={contactSubject}
                    setContactSubject={setContactSubject}
                    contactText={contactText}
                    setContactText={setContactText}
                    contactLoading={contactLoading}
                />
            )}
            {contactSuccess && <Alert />}
        </div>
    );
}

export default App;
