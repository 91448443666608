import React, {useState, useEffect, useRef, useCallback} from 'react';
import '../css/paypalButton.css';
import '../css/loader.css';
import LogRocket from "logrocket";
import SuccessfulPaymentAlert from './SuccessfulPaymentAlert'; // Import the new SuccessfulPaymentAlert component

const env = process.env.NODE_ENV;

let backend_address;
let paypal_client_id;
let subscription_id;

if (env === 'production') {
    backend_address = 'https://api.newsinfobot.com';
    LogRocket.init('ielsqo/chat-news');
    paypal_client_id = 'AXZ11QVMM4Uf1x1E5FKQckdbKsb-8rdFaPVjcOZGVoyLtj8Hs0jBXUeRtbYk1yNU1GeusP_dhqzgH89O';
    subscription_id = 'P-0WU74018EC278543AMZM7USQ';


} else {
    backend_address = 'http://localhost:5555';
    paypal_client_id = 'AaJYqDaU3hzPQDjqxx5nKQAvYEHMwaQ0NPceaOKRvLywgVvoX1xAW0fFdRTGYzwLFzYdtnzkM5AiJKlY';
    subscription_id = 'P-8NN36373TE076284AMZLUWIA';
}

const PaypalButton = ({modalIsOpen, setModalIsOpen}) => {
    const [paypalScriptLoaded, setPaypalScriptLoaded] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null); // State for the custom alert message
    const [apiKey, setApiKey] = useState(null); // State for the API key
    const [loading, setLoading] = useState(false); // State for loading spinner
    const paypalContainerRef = useRef(null);

    const loadPaypalScript = () => {
        let paypal_link = `https://www.paypal.com/sdk/js?client-id=${paypal_client_id}&vault=true&intent=subscription&currency=USD&locale=en_US`;
        if (!document.querySelector(`script[src="${paypal_link}"]`)) {
            const script = document.createElement('script');
            script.src = paypal_link;
            script.async = true;
            script.onload = () => {
                setPaypalScriptLoaded(true);
            };
            script.onerror = () => {
                console.error("PayPal SDK script failed to load.");
            };
            document.body.appendChild(script);
        } else {
            setPaypalScriptLoaded(true);
        }
    };

    useEffect(() => {
        loadPaypalScript();
    }, []);

    const handleSubscriptionSuccess = useCallback(async (data, subscriptionDetails) => {
        const email = subscriptionDetails.subscriber.email_address;
        const subscriptionId = data.subscriptionID;

        try {
            setModalIsOpen(false); // Close the modal after successful subscription
            setLoading(true); // Show loading spinner
            const response = await fetch(`${backend_address}/api/complete-subscription`, { // Ensure this matches your Flask server URL
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email, subscriptionId}),
            });

            if (response.ok) {
                const result = await response.json();
                setApiKey(result["api_key"]);
                setAlertMessage(`Subscription completed successfully!\n Your API key has been sent to ${email}.\n Please save it securely as it will not be generated again.\n`);
                setLoading(false); // Hide loading spinner
                document.title = 'Payment Alert!'; // Indicate alert on tab
            } else {
                console.error('Failed to send email');
                setLoading(false); // Hide loading spinner
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false); // Hide loading spinner
        }
    }, [setModalIsOpen]);

    useEffect(() => {
        if (modalIsOpen && paypalScriptLoaded) {
            if (window.paypal && paypalContainerRef.current) {
                paypalContainerRef.current.innerHTML = ""; // Clear previous buttons
                window.paypal.Buttons({
                    createSubscription: function (data, actions) {
                        return actions.subscription.create({
                            'plan_id': subscription_id,
                            'application_context': {
                                'shipping_preference': 'NO_SHIPPING',
                                'locale': 'en-US',
                                'user_action': 'SUBSCRIBE_NOW',
                            }
                        });
                    },
                    onApprove: async function (data, actions) {
                        const subscriptionDetails = await actions.subscription.get();
                        handleSubscriptionSuccess(data, subscriptionDetails);
                    }
                }).render(paypalContainerRef.current);
            }
        }
    }, [modalIsOpen, paypalScriptLoaded, handleSubscriptionSuccess]);

    return (
        <>
            {modalIsOpen && (
                <div className="Modal">
                    <div className="modal-content">
                        <div className="plan-details">
                            <h2 className="price">$25 Monthly Plan</h2>
                            {/*<p className="price">$25 per month - API access.</p>*/}
                            <p>Up to 1,000 requests per month.</p>
                            <p>Automatically renews each month.</p>
                            <p>Cancel anytime.</p>
                        </div>
                        <div id="paypal-button-container" ref={paypalContainerRef}></div>
                        <button className="close-button" onClick={() => setModalIsOpen(false)}>Close</button>
                    </div>
                </div>
            )}
            {loading && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            {alertMessage && apiKey && (
                <SuccessfulPaymentAlert
                    message={alertMessage}
                    apiKey={apiKey}
                    onClose={() => {
                        setAlertMessage(null);
                        document.title = 'Original Title'; // Reset tab title on close
                    }}
                />
            )}
        </>
    );
};

export default PaypalButton;
